/* eslint-disable sonarjs/no-duplicate-string */
import { ANALYTIC_FEATURES, SORT_DIRECTION } from 'constants/app.constant';
import { MenuOption } from 'models';
import { SHLegendItemPropType } from '../components/common/sh-legend-item/SHLegendItem';

export const NA = 'N/A';

export const SH_PATHS = {
  dashboard: '/shadowhealth',
  studentProfile: '/shadowhealth/studentProfile/:studentId',
  studentAssignmentView: '/shadowhealth/studentAssignmentView/:assignmentId',
  courseAggregate: '/shadowhealth/courseAggregate',
  cpra: '/cpra',
  cpraStudentResults: '/cpra/studentResults'
};

export const SH_OVERALL_CHART_RANGES = {
  AT_RISK: '< 50%',
  BELOW_ACCEPTABLE: '50-69%',
  ACCEPTABLE: '70-89%',
  RECOMMENDED: '≥ 90%'
};

export const SH_OVERALL_CHART_CONFIGS = {
  AT_RISK: {
    range: SH_OVERALL_CHART_RANGES.AT_RISK,
    fillColorClassName: 'u-els-fill-warn'
  },
  BELOW_ACCEPTABLE: {
    range: SH_OVERALL_CHART_RANGES.BELOW_ACCEPTABLE,
    fillColorClassName: 'u-els-fill-extended-orange-7'
  },
  ACCEPTABLE: {
    range: SH_OVERALL_CHART_RANGES.ACCEPTABLE,
    fillColorClassName: 'u-els-fill-confirm-on-dark'
  },
  RECOMMENDED: {
    range: SH_OVERALL_CHART_RANGES.RECOMMENDED,
    fillColorClassName: 'u-els-fill-extended-green-9'
  }
};

export const DASHBOARD_DETAILS_TABS = {
  STUDENT_PERFORMANCE_TAB: {
    index: 0,
    hash: '#byStudentPerformance'
  },
  STUDENT_ENGAGEMENT_TAB: {
    index: 1,
    hash: '#byStudentEngagement'
  },
  CLINICAL_JUDGMENT_MEASUREMENT_MODEL_TAB: {
    index: 2,
    hash: '#byCJMM'
  },
  ASSIGNMENT_SUMMARY_TAB: {
    index: 3,
    hash: '#byAssignmentSummary'
  },
  CATEGORY_STRENGTH_AND_WEAKNESSES_TAB: {
    index: 4,
    hash: '#byCategoryStrengthWeakness'
  }
} as const;

export const DASHBOARD_DETAILS_TABS_WITHOUT_CJMM = {
  STUDENT_PERFORMANCE_TAB: {
    index: 0,
    hash: '#byStudentPerformance'
  },
  STUDENT_ENGAGEMENT_TAB: {
    index: 1,
    hash: '#byStudentEngagement'
  },
  ASSIGNMENT_SUMMARY_TAB: {
    index: 2,
    hash: '#byAssignmentSummary'
  },
  CATEGORY_STRENGTH_AND_WEAKNESSES_TAB: {
    index: 3,
    hash: '#byCategoryStrengthWeakness'
  }
} as const;

export const STUDENT_PERFORMANCE_LEVELS = {
  AT_RISK: {
    backgroundColorClassName: 'u-els-background-color-warn',
    textColorClassName: 'u-custom-color-dark-2',
    displayText: '0-49%',
    tableBackgroundColorClassName: 'u-custom-bg-color-bizarre',
    value: 49
  },
  BELOW_ACCEPTABLE: {
    backgroundColorClassName: 'u-els-background-color-extended-orange-7',
    textColorClassName: 'u-custom-color-dark-2',
    displayText: '50-69%',
    tableBackgroundColorClassName: 'u-custom-bg-color-citrine-white'
  },
  ACCEPTABLE: {
    backgroundColorClassName: 'u-els-background-color-confirm-on-dark',
    textColorClassName: 'u-custom-color-dark-2',
    displayText: '70-89%',
    tableBackgroundColorClassName: 'u-custom-bg-color-frost',
    value: 70
  },
  RECOMMENDED: {
    backgroundColorClassName: 'u-els-background-color-extended-green-9',
    textColorClassName: 'u-custom-color-dark-2',
    displayText: '90-100%',
    tableBackgroundColorClassName: 'u-custom-bg-color-tea-green',
    value: 90
  }
};

export const STUDENT_ENGAGEMENT_LEVELS = {
  COMPARISON_CHART_COLOR: {
    atRisk: 'u-custom-bg-color-pink-1',
    recommended: 'u-custom-bg-color-green-1'
  }
};

export const CATEGORY_DROPDOWN_KEYS = Object.freeze({
  SDC: 'SUBJECTIVE_DATA_COLLECTION',
  ODC: 'OBJECTIVE_DATA_COLLECTION',
  EAE: 'EDUCATION_AND_EMPATHY',
  CP: 'CARE_PLAN'
});

export const CATEGORY_DROPDOWN_OPTIONS: Array<MenuOption> = [
  {
    key: CATEGORY_DROPDOWN_KEYS.SDC,
    name: 'Subjective Data Collection'
  },
  {
    key: CATEGORY_DROPDOWN_KEYS.ODC,
    name: 'Objective Data Collection'
  },
  {
    key: CATEGORY_DROPDOWN_KEYS.EAE,
    name: 'Education And Empathy'
  },
  {
    key: CATEGORY_DROPDOWN_KEYS.CP,
    name: 'Care Plan'
  }
];

export const DISTRIBUTION_OF_SCORES_RANGES = Object.freeze({
  SPI_FROM_10_TO_49: 'Below 50%',
  SPI_FROM_50_TO_59: '50-59%',
  SPI_FROM_60_TO_69: '60-69%',
  SPI_FROM_70_TO_79: '70-79%',
  SPI_FROM_80_TO_89: '80-89%',
  SPI_FROM_90_TO_99: '90% & Above'
});

export const TABLE_SELECTORS = {
  STUDENT_ENGAGEMENT: {
    averagePatientInteractionTimeMinutesColumn: 'c-sh-student-engagement-table__avg-patient-interaction-time-header-column'
  },
  STUDENT_PERFORMANCE: {
    spiColumn: 'c-sh-student-performance-table__spi-header-column'
  },
  CATEGORY_STRENGTH_AND_WEAKNESSES: {
    averageScoreColumn: 'c-sh-subjective-data-collection-category-table__header-average-score'
  },
  CLINICAL_JUDGMENT_MEASUREMENT_MODEL_TAB: {
    averageScoreColumn: 'c-sh-cjmm-table__header-average-score'
  }
};

export const INTERACTION_BETWEEN_CARD_AND_TABLE = {
  [DASHBOARD_DETAILS_TABS.STUDENT_ENGAGEMENT_TAB.hash]: {
    tableColumn: {
      headerSelector: TABLE_SELECTORS.STUDENT_ENGAGEMENT.averagePatientInteractionTimeMinutesColumn
    },
    sortDirection: SORT_DIRECTION.ASC
  },
  [DASHBOARD_DETAILS_TABS.STUDENT_PERFORMANCE_TAB.hash]: {
    tableColumn: {
      headerSelector: TABLE_SELECTORS.STUDENT_PERFORMANCE.spiColumn
    },
    sortDirection: SORT_DIRECTION.ASC
  },
  [DASHBOARD_DETAILS_TABS.CATEGORY_STRENGTH_AND_WEAKNESSES_TAB.hash]: {
    tableColumn: {
      headerSelector: TABLE_SELECTORS.CATEGORY_STRENGTH_AND_WEAKNESSES.averageScoreColumn
    },
    sortDirection: SORT_DIRECTION.ASC
  },
  [DASHBOARD_DETAILS_TABS.CLINICAL_JUDGMENT_MEASUREMENT_MODEL_TAB.hash]: {
    tableColumn: {
      headerSelector: TABLE_SELECTORS.CLINICAL_JUDGMENT_MEASUREMENT_MODEL_TAB.averageScoreColumn
    },
    sortDirection: SORT_DIRECTION.ASC
  }
};

export const STUDENT_DETAILS_TABS = {
  ASSIGNMENT_PERFORMANCE_TAB: {
    index: 0,
    hash: '#byAssignmentPerformance'
  },
  ASSIGNMENT_ENGAGEMENT_TAB: {
    index: 1,
    hash: '#byAssignmentEngagement'
  },
  TRENDS_TAB: {
    index: 2,
    hash: '#byTrends'
  },
  CLINICAL_JUDGMENT_MEASUREMENT_MODEL_TAB: {
    index: 3,
    hash: '#byCJMM'
  }
};

export const STUDENT_DETAILS_TABLE_SELECTORS = {
  ASSIGNMENT_ENGAGEMENT: {
    parentInteractionTime: 'c-sh-assignment-engagement-table__header-parent-interaction-time'
  },
  ASSIGNMENT_PERFORMANCE: {
    spiOrDceColumn: 'c-sh-assignment-performance-table__header-dce-spi'
  }
};

export const STUDENT_DETAILS_INTERACTION_BETWEEN_CARD_AND_TABLE = {
  [STUDENT_DETAILS_TABS.ASSIGNMENT_ENGAGEMENT_TAB.hash]: {
    tableColumn: {
      headerSelector: STUDENT_DETAILS_TABLE_SELECTORS.ASSIGNMENT_ENGAGEMENT.parentInteractionTime
    },
    sortDirection: SORT_DIRECTION.ASC
  },
  [STUDENT_DETAILS_TABS.ASSIGNMENT_PERFORMANCE_TAB.hash]: {
    tableColumn: {
      headerSelector: STUDENT_DETAILS_TABLE_SELECTORS.ASSIGNMENT_PERFORMANCE.spiOrDceColumn
    },
    sortDirection: SORT_DIRECTION.ASC
  }
};

export const STUDENT_ASSIGNMENT_VIEW_DETAILS_TABS = {
  STUDENT_PERFORMANCE_TAB: {
    index: 0,
    hash: '#byStudentPerformance'
  },
  STUDENT_ENGAGEMENT_TAB: {
    index: 1,
    hash: '#byStudentEngagement'
  },
  CLINICAL_JUDGMENT_MEASUREMENT_MODEL_TAB: {
    index: 2,
    hash: '#byCJMM'
  }
};

export const STUDENT_ASSIGNMENT_VIEW_DETAILS_TABLE_SELECTORS = {
  STUDENT_ENGAGEMENT: {
    parentInteractionTime: 'c-sh-assignment-view-student-engagement-table__header-parent-interaction-time'
  },
  STUDENT_PERFORMANCE: {
    spiOrDceColumn: 'c-sh-assignment-view-student-performance-table__header-dce-spi'
  },
  CLINICAL_JUDGMENT_MEASUREMENT_MODEL_TAB: {
    spiOrDceColumn: 'c-sh-cjmm-table__header-dce-spi'
  }
};

export const STUDENT_ASSIGNMENT_VIEW_DETAILS_INTERACTION_BETWEEN_CARD_AND_TABLE = {
  [STUDENT_ASSIGNMENT_VIEW_DETAILS_TABS.STUDENT_ENGAGEMENT_TAB.hash]: {
    tableColumn: {
      headerSelector: STUDENT_ASSIGNMENT_VIEW_DETAILS_TABLE_SELECTORS.STUDENT_ENGAGEMENT.parentInteractionTime
    },
    sortDirection: SORT_DIRECTION.ASC
  },
  [STUDENT_ASSIGNMENT_VIEW_DETAILS_TABS.STUDENT_PERFORMANCE_TAB.hash]: {
    tableColumn: {
      headerSelector: STUDENT_ASSIGNMENT_VIEW_DETAILS_TABLE_SELECTORS.STUDENT_PERFORMANCE.spiOrDceColumn
    },
    sortDirection: SORT_DIRECTION.ASC
  },
  [STUDENT_ASSIGNMENT_VIEW_DETAILS_TABS.CLINICAL_JUDGMENT_MEASUREMENT_MODEL_TAB.hash]: {
    tableColumn: {
      headerSelector: STUDENT_ASSIGNMENT_VIEW_DETAILS_TABLE_SELECTORS.CLINICAL_JUDGMENT_MEASUREMENT_MODEL_TAB.spiOrDceColumn
    },
    sortDirection: SORT_DIRECTION.ASC
  }
};

export const DEFAULT_COURSE_SECTION_ID = 0;
export const DEFAULT_SELECTED_COURSE_ID = 0;
export const DEFAULT_FALLBACK_MESSAGE = 'Invalid instructor id';
export const STUDENT_PERFORMANCE_AVERAGE_TYPE = Object.freeze({
  SPI: 'SPI',
  DCE: 'DCE'
});

export const CELL_NO_PADDING_CLASS_NAME = 'u-els-padding-none';

export const SH_ROLE = Object.freeze({
  INSTRUCTOR: 'instructor',
  STUDENT: 'student'
});

export const SH_COURSE_AGGREGATE_CHART_RANGES = {
  AT_RISK: 'Below 50%',
  BELOW_ACCEPTABLE: '50-69%',
  ACCEPTABLE: '70-89%',
  RECOMMENDED: '90% & Above'
};

export const SH_COURSE_AGGREGATE_CHART_CONFIGS = {
  AT_RISK: {
    range: SH_COURSE_AGGREGATE_CHART_RANGES.AT_RISK,
    fillColorClassName: 'u-els-fill-warn'
  },
  BELOW_ACCEPTABLE: {
    range: SH_COURSE_AGGREGATE_CHART_RANGES.BELOW_ACCEPTABLE,
    fillColorClassName: 'u-els-fill-extended-orange-7'
  },
  ACCEPTABLE: {
    range: SH_COURSE_AGGREGATE_CHART_RANGES.ACCEPTABLE,
    fillColorClassName: 'u-els-fill-confirm-on-dark'
  },
  RECOMMENDED: {
    range: SH_COURSE_AGGREGATE_CHART_RANGES.RECOMMENDED,
    fillColorClassName: 'u-els-fill-extended-green-9'
  }
};

export const STUDENT_DETAILS_TOGGLE_PANELS_KEYS = {
  CJMM: 'CLINICAL_JUDGMENT_MEASUREMENT_MODEL_TAB',
  AP: 'ASSIGNMENT_PERFORMANCE',
  AE: 'ASSIGNMENT_ENGAGEMENT',
  TRENDS: 'TRENDS',
  SMD: 'SEE_MORE_DASHBOARDS'
};

export const ASSIGNMENT_VIEW_TOGGLE_PANELS_KEYS = {
  CJMM: 'CLINICAL_JUDGMENT_MEASUREMENT_MODEL_TAB',
  SP: 'STUDENT_PERFORMANCE',
  SE: 'STUDENT_ENGAGEMENT',
  SMD: 'SEE_MORE_DASHBOARDS'
};

export const ASSIGNMENT_VIEW_TOGGLE_PANELS: MenuOption[] = [
  {
    key: ASSIGNMENT_VIEW_TOGGLE_PANELS_KEYS.SP,
    name: 'Student Performance'
  },
  {
    key: ASSIGNMENT_VIEW_TOGGLE_PANELS_KEYS.SE,
    name: 'Student Engagement'
  },
  {
    key: ASSIGNMENT_VIEW_TOGGLE_PANELS_KEYS.CJMM,
    name: 'Clinical Judgment Measurement Model'
  },
  {
    key: ASSIGNMENT_VIEW_TOGGLE_PANELS_KEYS.SMD,
    name: 'See More Dashboards'
  }
];

export const STUDENT_DETAILS_TOGGLE_PANELS: MenuOption[] = [
  {
    key: STUDENT_DETAILS_TOGGLE_PANELS_KEYS.AP,
    name: 'Assignment Performance'
  },
  {
    key: STUDENT_DETAILS_TOGGLE_PANELS_KEYS.AE,
    name: 'Assignment Engagement'
  },
  {
    key: STUDENT_DETAILS_TOGGLE_PANELS_KEYS.TRENDS,
    name: 'Trends'
  },
  {
    key: STUDENT_DETAILS_TOGGLE_PANELS_KEYS.CJMM,
    name: 'Clinical Judgment Measurement Model'
  },
  {
    key: STUDENT_DETAILS_TOGGLE_PANELS_KEYS.SMD,
    name: 'See More Dashboards'
  }
];

export const SH_DISTRIBUTION_OF_STUDENT_PERFORMANCE_CHART_RANGES = {
  NEEDS_IMPROVEMENT: 'Needs Improvement',
  DEVELOPING: 'Developing',
  ACCEPTABLE: 'Acceptable'
};

export const CJMM_STUDENT_PERFORMANCE_LEVELS = {
  NEEDS_IMPROVEMENT: {
    fillColorClassName: 'u-els-fill-custom-red-1',
    displayText: 'Needs Improvement (Below 25%)',
    backgroundColorClassName: 'u-custom-bg-color-red-1',
    textColorClassName: 'u-els-color-warn',
    tableBackgroundColorClassName: 'u-custom-bg-color-red-3',
    value: 26,
    range: SH_DISTRIBUTION_OF_STUDENT_PERFORMANCE_CHART_RANGES.NEEDS_IMPROVEMENT
  },
  DEVELOPING: {
    fillColorClassName: 'u-els-fill-custom-orange-3',
    displayText: 'Developing (26-74%)',
    backgroundColorClassName: 'u-custom-bg-color-orange-3',
    textColorClassName: 'u-custom-color-orange-3',
    tableBackgroundColorClassName: 'u-custom-bg-color-green-2',
    value: 75,
    range: SH_DISTRIBUTION_OF_STUDENT_PERFORMANCE_CHART_RANGES.DEVELOPING
  },
  ACCEPTABLE: {
    fillColorClassName: 'u-els-fill-custom-green-4',
    displayText: 'Acceptable (75-100%)',
    backgroundColorClassName: 'u-custom-bg-color-green-3',
    textColorClassName: 'u-custom-color-green-1',
    tableBackgroundColorClassName: 'u-custom-bg-color-green-1',
    range: SH_DISTRIBUTION_OF_STUDENT_PERFORMANCE_CHART_RANGES.ACCEPTABLE
  }
} as const;

export const CJMM_CARD_TYPES = {
  RECOGNIZE_CUES: 'Recognize Cues',
  ANALYZE_CUES: 'Analyze Cues',
  PRIORITIZE_HYPOTHESIS: 'Prioritize Hypothesis',
  GENERATE_SOLUTIONS: 'Generate Solutions',
  TAKE_ACTIONS: 'Take Actions',
  EVALUATE_OUTCOMES: 'Evaluate Outcomes'
};

export const EDUCATION_AND_EMPATHY = {
  EDUCATION: 'Education',
  EMPATHY: 'Empathy'
};

export const STUDENT_ALERT_OPPORTUNITIES = {
  NOT_ENCOUNTERED: 'Not Encountered',
  NOT_FOLLOWED_UP: 'Not Followed Up'
};

const { NEEDS_IMPROVEMENT, DEVELOPING, ACCEPTABLE } = CJMM_STUDENT_PERFORMANCE_LEVELS;
const wrapperClassName = 'u-els-margin-right-1x';
export const CJMM_LEGEND_ITEMS: SHLegendItemPropType[] = [
  {
    content: NEEDS_IMPROVEMENT.displayText,
    colorClassName: NEEDS_IMPROVEMENT.backgroundColorClassName,
    wrapperClassName
  },
  {
    content: DEVELOPING.displayText,
    colorClassName: DEVELOPING.backgroundColorClassName,
    wrapperClassName
  },
  {
    content: ACCEPTABLE.displayText,
    colorClassName: ACCEPTABLE.backgroundColorClassName,
    wrapperClassName
  }
];

export const NUM_OF_ITEMS_PER_PAGE = 10;

export const CJMM_OVERALL_FIRST_HEADER = 'Construct';

export const CJMM_OVERALL_SECOND_HEADER = 'Class Average Score';

export const DEFAULT_COURSE_AGGREGATE_EMPTY_STATE_TEXT = 'The completed assignment(s) are not included in the DCE score calculation.';

export const CPRA_TITLE = 'Clinical Practice Readiness Assessment';

export const CPRA_CLASS_DISTRIBUTION_CHART_RANGES = {
  EMERGING: 'Emerging',
  DEVELOPING: 'Developing',
  PROFICIENT: 'Proficient',
  ADVANCED: 'Advanced'
};

export const CPRA_CLASS_DISTRIBUTION = {
  EMERGING: {
    fillColorClassName: 'u-els-fill-custom-red-2',
    textColorClassName: 'u-els-color-white',
    displayText: CPRA_CLASS_DISTRIBUTION_CHART_RANGES.EMERGING,
    tableBackgroundColorClassName: 'u-custom-bg-color-red-4',
    value: 49,
    range: CPRA_CLASS_DISTRIBUTION_CHART_RANGES.EMERGING,
    isChecked: true
  },
  DEVELOPING: {
    fillColorClassName: 'u-els-fill-custom-orange-2',
    textColorClassName: 'u-els-color-white',
    displayText: CPRA_CLASS_DISTRIBUTION_CHART_RANGES.DEVELOPING,
    tableBackgroundColorClassName: 'u-custom-bg-color-orange-2',
    range: CPRA_CLASS_DISTRIBUTION_CHART_RANGES.DEVELOPING,
    isChecked: true
  },
  PROFICIENT: {
    fillColorClassName: 'u-els-fill-custom-green-2',
    textColorClassName: 'u-els-color-black',
    displayText: CPRA_CLASS_DISTRIBUTION_CHART_RANGES.PROFICIENT,
    tableBackgroundColorClassName: 'u-custom-bg-color-green-4',
    value: 70,
    range: CPRA_CLASS_DISTRIBUTION_CHART_RANGES.PROFICIENT,
    isChecked: false
  },
  ADVANCED: {
    fillColorClassName: 'u-els-fill-custom-green-3',
    textColorClassName: 'u-els-color-white',
    displayText: CPRA_CLASS_DISTRIBUTION_CHART_RANGES.ADVANCED,
    tableBackgroundColorClassName: 'u-custom-bg-color-green-5',
    value: 90,
    range: CPRA_CLASS_DISTRIBUTION_CHART_RANGES.ADVANCED,
    isChecked: false
  }
} as const;

export const CPRA_PRACTICE_READINESS_BY_CONSTRUCT = {
  ASSESSMENT: 'Assessment',
  COMMUNICATION: 'Communication',
  EDUCATION: 'Education',
  SAFETY: 'Safety',
  CLINICAL_JUDGMENT: 'Clinical Judgment'
};

export const CPRA_TABLE_SELECTORS = {
  ASSESSMENT: 'c-sh-cpra-table__header-assessment',
  COMMUNICATION: 'c-sh-cpra-table__header-communication',
  EDUCATION: 'c-sh-cpra-table__header-education',
  SAFETY: 'c-sh-cpra-table__header-safety',
  CLINICAL_JUDGMENT: 'c-sh-cpra-table__header-clinical-judgment'
};

export const CPRA_RESOURCE_URL = 'https://d2intz2u2uiw0q.cloudfront.net/clinical_practice_readiness_assessment/';

export const CPRA_INTERACTION_BETWEEN_CARD_AND_TABLE = {
  [CPRA_PRACTICE_READINESS_BY_CONSTRUCT.ASSESSMENT]: {
    tableColumn: {
      headerSelector: CPRA_TABLE_SELECTORS.ASSESSMENT
    },
    sortDirection: SORT_DIRECTION.ASC
  },
  [CPRA_PRACTICE_READINESS_BY_CONSTRUCT.COMMUNICATION]: {
    tableColumn: {
      headerSelector: CPRA_TABLE_SELECTORS.COMMUNICATION
    },
    sortDirection: SORT_DIRECTION.ASC
  },
  [CPRA_PRACTICE_READINESS_BY_CONSTRUCT.EDUCATION]: {
    tableColumn: {
      headerSelector: CPRA_TABLE_SELECTORS.EDUCATION
    },
    sortDirection: SORT_DIRECTION.ASC
  },
  [CPRA_PRACTICE_READINESS_BY_CONSTRUCT.SAFETY]: {
    tableColumn: {
      headerSelector: CPRA_TABLE_SELECTORS.SAFETY
    },
    sortDirection: SORT_DIRECTION.ASC
  },
  [CPRA_PRACTICE_READINESS_BY_CONSTRUCT.CLINICAL_JUDGMENT]: {
    tableColumn: {
      headerSelector: CPRA_TABLE_SELECTORS.CLINICAL_JUDGMENT
    },
    sortDirection: SORT_DIRECTION.ASC
  }
};

export const STUDENTS_BELOW_PROFICIENT = 'Student';

export const SH_CJMM_CONSTRUCTS = {
  ANALYZE_CUES: {
    id: 'analyze_cues',
    displayValue: 'Analyze Cues'
  },
  EVALUATE_OUTCOMES: {
    id: 'evaluate_outcomes',
    displayValue: 'Evaluate Outcomes'
  },
  GENERATE_SOLUTIONS: {
    id: 'generate_solutions',
    displayValue: 'Generate Solutions'
  },
  PRIORITIZE_HYPOTHESIS: {
    id: 'prioritize_hypothesis',
    displayValue: 'Prioritize Hypothesis'
  },
  RECOGNIZE_CUES: {
    id: 'recognize_cues',
    displayValue: 'Recognize Cues'
  },
  TAKE_ACTIONS: {
    id: 'take_actions',
    displayValue: 'Take Actions'
  }
};

export const CPRA_CONSTRUCT_AA_FEATURE_MAP = {
  [CPRA_PRACTICE_READINESS_BY_CONSTRUCT.ASSESSMENT]: ANALYTIC_FEATURES.CPRA_ASSESSMENT_CLICK,
  [CPRA_PRACTICE_READINESS_BY_CONSTRUCT.CLINICAL_JUDGMENT]: ANALYTIC_FEATURES.CPRA_CLINICAL_JUDGMENT_CLICK,
  [CPRA_PRACTICE_READINESS_BY_CONSTRUCT.COMMUNICATION]: ANALYTIC_FEATURES.CPRA_COMMUNICATION_CLICK,
  [CPRA_PRACTICE_READINESS_BY_CONSTRUCT.EDUCATION]: ANALYTIC_FEATURES.CPRA_EDUCATION_CLICK,
  [CPRA_PRACTICE_READINESS_BY_CONSTRUCT.SAFETY]: ANALYTIC_FEATURES.CPRA_SAFETY_CLICK
};

export const DISTRIBUTION_AA_FEATURE_MAP = {
  [CPRA_CLASS_DISTRIBUTION_CHART_RANGES.ADVANCED]: ANALYTIC_FEATURES.CPRA_ADVANCED_FILTER_CLICK,
  [CPRA_CLASS_DISTRIBUTION_CHART_RANGES.DEVELOPING]: ANALYTIC_FEATURES.CPRA_DEVELOPING_FILTER_CLICK,
  [CPRA_CLASS_DISTRIBUTION_CHART_RANGES.EMERGING]: ANALYTIC_FEATURES.CPRA_EMERGING_FILTER_CLICK,
  [CPRA_CLASS_DISTRIBUTION_CHART_RANGES.PROFICIENT]: ANALYTIC_FEATURES.CPRA_PROFICIENT_FILTER_CLICK
};

export const CJMM_CONSTRUCT_AA_FEATURE_MAP = {
  [SH_CJMM_CONSTRUCTS.ANALYZE_CUES.id]: ANALYTIC_FEATURES.PRD_DASHBOARD_CJMM_ANALYZE_CUES_CONSTRUCT_CLICK,
  [SH_CJMM_CONSTRUCTS.EVALUATE_OUTCOMES.id]: ANALYTIC_FEATURES.PRD_DASHBOARD_CJMM_EVALUATE_OUTCOMES_CONSTRUCT_CLICK,
  [SH_CJMM_CONSTRUCTS.GENERATE_SOLUTIONS.id]: ANALYTIC_FEATURES.PRD_DASHBOARD_CJMM_GENERATE_SOLUTIONS_CONSTRUCT_CLICK,
  [SH_CJMM_CONSTRUCTS.PRIORITIZE_HYPOTHESIS.id]: ANALYTIC_FEATURES.PRD_DASHBOARD_CJMM_PRIORITIZE_HYPOTHESIS_CONSTRUCT_CLICK,
  [SH_CJMM_CONSTRUCTS.RECOGNIZE_CUES.id]: ANALYTIC_FEATURES.PRD_DASHBOARD_CJMM_RECOGNIZE_CUES_CONSTRUCT_CLICK,
  [SH_CJMM_CONSTRUCTS.TAKE_ACTIONS.id]: ANALYTIC_FEATURES.PRD_DASHBOARD_CJMM_TAKE_ACTIONS_CONSTRUCT_CLICK
};

export const ACCEPTABLE_SCORE = 70;
